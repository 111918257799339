import * as React from "react";
import Seo from "../components/seo";
import {graphql} from "gatsby";
import * as styles from "./flat.module.scss";
import ContactForm from "../components/contact-form/contact-form";
import Gallery from "@browniebroke/gatsby-image-gallery";
import Header from "../components/header/header";
import classNames from "classnames";

const FlatPage = ({data}) => {
  const flat = data?.api?.flats[0];

  const images = flat?.gallery?.map(({file}, index) => ({
    ...file.childImageSharp,
    caption: `Obrázek ${index + 1}`
  }));

  const lightboxOptions = {
    imageLoadErrorMessage: 'Nelze načíst obrázek',
    nextLabel: 'Další',
    prevLabel: 'Předchozí',
    zoomInLabel: 'Přiblížit',
    zoomOutLabel: 'Oddálit',
    closeLabel: 'Zavřít',
  }

  return (
    <>
      <Seo title={flat?.id} />

      <div className={styles.layoutWrapper}>
        <Header />
        <img src={`${process.env.GATSBY_API_BASE_URL}${flat?.card?.path}`}
             className={styles.card}
             alt={"karta bytu"}
        />
      </div>
      <main className={styles.main}>
        <div className={styles.container}>
          {/*
          <GatsbyImage alt={"karta bytu"}
                       image={getImage(flat?.card?.file)}
                       className={styles.card}
                       breakpoints={[]}
                       objectFit={"contain"}
          />
          */}

          { flat?.state !== "available" &&
            <h5 className={styles.text} style={{opacity: .3}}>Tento byt již není k dispozici</h5>
          }

          <div className={styles.text} dangerouslySetInnerHTML={{__html: flat?.text}} />

          {(flat?.level1?.length > 0 || flat?.level2?.length > 0) ? (
            <>
              <h3 className={styles.priceLevelsTitle}>Úrovně dokončení</h3>

              <div className={styles.priceLevels}>
                {flat?.level1?.length > 0 && (
                  <a href={`/byty/${flat?.id}/uroven1`} target={"_blank"} rel={"noreferrer"}>
                    <div className={classNames({
                      [styles.level]: true,
                      [styles.highlighted]: false
                    })}>
                      <h5>Úroveň I</h5>
                      <ul>
                        {flat?.level1?.slice(0, flat?.level1?.length - 1).map(x => <li key={`l1-${x}`}>{x?.value}</li>)}
                      </ul>

                      {flat?.level1Price !== null && (
                        <div className={styles.price}>
                          <p>{flat?.level1Price}</p>
                        </div>
                      )}

                    </div>
                  </a>
                )}
                {flat?.level2?.length > 0 && (
                  <a href={`/byty/${flat?.id}/uroven2`} target={"_blank"} rel={"noreferrer"}>
                    <div className={classNames({
                      [styles.level]: true,
                      [styles.highlighted]: true
                    })}>
                      <h5>Úroveň II</h5>
                      <ul>
                        {flat?.level2?.slice(0, flat?.level2?.length - 1).map(x => <li key={`l2-${x}`}>{x?.value}</li>)}
                      </ul>

                      {flat?.level2Price !== null && (
                        <div className={styles.price}>
                          <p>{flat?.level2Price}</p>
                        </div>
                      )}

                    </div>
                  </a>
                )}
              </div>
            </>
          ) : (
            <h5 style={{opacity: 0.3, width: "100%", textAlign: "center"}}>Pro cenovou nabídku nás kontaktujte</h5>
          )}

          {images?.length > 0 && (
            <div className={styles.galleryWrapper}>
              <h4>Galerie</h4>
              <Gallery images={images} lightboxOptions={lightboxOptions} imgClass={styles.galleryImage} />
            </div>
          )}

          { flat?.state === "available" &&
            <ContactForm
              flatId={flat?.id} contactInfo={data?.api?.contactInfo} title={"NEZÁVAZNÁ REZERVACE"}
            />
          }

          <div className={styles.copyright}>
            <small>Copyright &copy; 2022 VP Developers</small>
            <small>Created by <a href="https://ledl.dev" target="_blank" rel="noreferrer">Martin Lédl</a></small>
          </div>
        </div>
      </main>
    </>
  )
}

export const query = graphql`
    query FlatPageQuery($filter: API_JsonType) {
        api {
            flats:flatsCollection(filter: $filter) {
                id
                text
                state
                price
                card {
                    path
                    file {
                        childImageSharp {
                            gatsbyImageData(layout: FULL_WIDTH)
                        }
                    }
                }
                level1 {
                    value
                }
                level1Price
                level2 {
                    value
                }
                level2Price
                gallery {
                    path
                    file {
                        childImageSharp {
                            thumb: gatsbyImageData(
                                width: 270
                                height: 270
                                placeholder: BLURRED
                            )
                            full: gatsbyImageData(layout: FULL_WIDTH)
                        }
                    }
                }
            }
            contactInfo:contactSingleton {
                email
                phone
                name
            }
        }
    }
`;

export default FlatPage;
